import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const FeedbackSubmit = ({ handlerSubmit }) => (
  <div className="PgIWF-Feedback__Submit" onClick={handlerSubmit}>
    submit
  </div>
);

FeedbackSubmit.propTypes = {
  handlerSubmit: PropTypes.func.isRequired,
};

export default FeedbackSubmit;
