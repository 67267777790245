import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import FeedbackTitle from "../FeedbackTitle";
import "./index.scss";
import { useSelector } from "react-redux";

const FeedbackSelectionSection = (props) => {
  const {
    listOfAnswers,
    title,
    surveyFieldName,
    type,
    stateUpdateHandler,
    componentRef,
  } = props;
  const {
    [surveyFieldName]: { error, value },
  } = useSelector((state) => state.waFeedback);

  const checkAnswer = (currentAnswer, answer) => {
    if (type === "IS_VALUE_LIST") {
      return currentAnswer.length !== 0 && currentAnswer.includes(answer);
    }
    return currentAnswer === answer;
  };

  return (
    <div
      className={`PgIWF-Feedback-SelectionSection-${surveyFieldName}`}
      ref={componentRef}
    >
      <FeedbackTitle error={error} title={title} />
      <div
        className={`PgIWF-Feedback-SelectionSection-${surveyFieldName}__wrapper`}
      >
        {listOfAnswers.map((answer, i) => (
          <div
            key={i}
            className={getTrueClasses(
              `PgIWF-Feedback-SelectionSection-${surveyFieldName}-item`,
              error &&
                `PgIWF-Feedback-SelectionSection-${surveyFieldName}-item_error`,
              checkAnswer(value, answer) &&
                `PgIWF-Feedback-SelectionSection-${surveyFieldName}-item_active`
            )}
            onClick={() => {
              stateUpdateHandler(type, surveyFieldName, "value", answer);
            }}
          >
            <div
              className={`PgIWF-Feedback-SelectionSection-${surveyFieldName}-item__checkbox`}
            >
              <div
                className={`PgIWF-Feedback-SelectionSection-${surveyFieldName}-item__checkbox__inner`}
              />
            </div>
            <div
              className={`PgIWF-Feedback-SelectionSection-${surveyFieldName}-item__name`}
            >
              {answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

FeedbackSelectionSection.propTypes = {
  listOfAnswers: PropTypes.array.isRequired,
  surveyFieldName: PropTypes.string.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default FeedbackSelectionSection;
