import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import { useSelector } from "react-redux";
import FeedbackTitle from "../FeedbackTitle";
import FeedbackLocationDescription from "./FeedbackLocationDescription";
import "./index.scss";

const FeedbackLocation = ({
  locationOfInterest,
  stateUpdateHandler,
  componentRef,
}) => {
  const { listLocations, money } = locationOfInterest;
  const {
    location: { error, value },
  } = useSelector((state) => state.waFeedback);
  return (
    <div className="PgIWF-Feedback-Location" ref={componentRef}>
      <FeedbackTitle
        error={error}
        title="1. Which Amazon location are you interested in?"
      />
      <div className="PgIWF-Feedback-Location__wrapper">
        {listLocations.map(({ icon, location }) => (
          <div
            key={location}
            className={getTrueClasses(
              "PgIWF-Feedback-Location-item",
              error && "PgIWF-Feedback-Location-item_error",
              value === location && "PgIWF-Feedback-Location-item_active"
            )}
            onClick={() =>
              stateUpdateHandler("IS_VALUE", "location", "value", location)
            }
          >
            <div className="PgIWF-Feedback-Location-item__checkbox">
              <div className="PgIWF-Feedback-Location-item__checkbox__inner" />
            </div>
            <img src={icon} alt="" />
            <div className="PgIWF-Feedback-Location-item__name">{location}</div>
          </div>
        ))}
      </div>
      <FeedbackLocationDescription location={value} money={money} />
    </div>
  );
};

FeedbackLocation.propTypes = {
  stateUpdateHandler: PropTypes.func.isRequired,
};

export default FeedbackLocation;
