import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const FeedbackTitle = ({ error, title, customClass }) => (
  <p
    className={getTrueClasses(
      "PgIWF-Feedback-Title",
      customClass,
      !!error && "PgIWF-Feedback-Title_error"
    )}
  >
    {title} <span>required</span>
  </p>
);

FeedbackTitle.propTypes = {
  error: PropTypes.bool,
  title: PropTypes.string,
  customClass: PropTypes.string,
};

export default FeedbackTitle;
