import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { waFeedbackActions } from "../../../store/slice/wa-feedback-slice";
import { checkValue } from "../../../helpers";
import Feedback from "../../../components/iframe/wa-feedback/Feedback";
import { ExtensionController } from "../../../controllers/common/extensionController";

const FeedbackContainer = ({ isLoading, setIsLoading, getParams }) => {
  const [isFinalPopupState, setIsFinalPopupState] = useState(false);
  const locationRef = useRef(null);
  const preferredPricesRef = useRef(null);
  const differentProductSizesRef = useRef(null);
  const productSizeRef = useRef(null);
  const criteriaSortRef = useRef(null);

  const listRef = [
    locationRef,
    preferredPricesRef,
    differentProductSizesRef,
    productSizeRef,
    criteriaSortRef,
  ];

  const state = useSelector((state) => state.waFeedback);
  const dispatch = useDispatch();

  const updateState = (key, fieldName, value) => {
    dispatch(
      waFeedbackActions.updateState({
        key,
        fieldName,
        value,
      })
    );
  };

  const checkErrorIndexes = (array, index) => {
    const arr = [...array];
    if (array[index] === -1) {
      return true;
    } else if (array[index] === -2) {
      return false;
    }
    arr.splice(index, 1);
    return arr.includes(array[index]);
  };

  const checkIndexes = (indexes) =>
    indexes.findIndex((array, i) => checkErrorIndexes(indexes, i)) !== -1;

  const handlerInput = (key, fieldName, value) => {
    const inputValue = value.target.value;
    const name = value.target.name;
    const error =
      name === "email" ? !!checkValue("email", inputValue) : !inputValue;
    if (name === "criteriaSort") {
      updateState(key, fieldName, inputValue);
    } else {
      updateState(key, fieldName, inputValue);
      state[key].error !== null && updateState(key, "error", error);
    }
  };
  const handlerValue = (key, fieldName, value) => {
    updateState(key, fieldName, value);
    updateState(key, "error", !value);
  };
  const handlerValueList = (key, fieldName, value) => {
    let arr = [...state[key][fieldName]];
    if (arr.length === 0) {
      arr.push(value);
    } else {
      const index = arr.indexOf(value);
      const indexTwo = arr.indexOf("Doesn't matter");
      if (value === "Doesn't matter" && indexTwo === -1) {
        arr = ["Doesn't matter"];
      } else if (index === -1 && indexTwo !== -1) {
        arr.splice(indexTwo, 1);
        arr.push(value);
      } else {
        if (index !== -1) {
          arr.splice(index, 1);
        } else {
          arr.push(value);
        }
      }
    }
    updateState(key, fieldName, arr);
    updateState(key, "error", arr.length === 0);
  };
  const handlerValueListIndex = (key, fieldName, value, index) => {
    const array = [...state[key][fieldName]];
    array.splice(index, 1, value);
    updateState(key, fieldName, array);
    state[key].error !== null && updateState(key, "error", checkIndexes(array));
  };
  const stateUpdateHandler = (type, key, fieldName, value, index) => {
    switch (type) {
      case "IS_INPUT":
        handlerInput(key, fieldName, value);
        break;
      case "IS_VALUE":
        handlerValue(key, fieldName, value);
        break;
      case "IS_VALUE_LIST":
        handlerValueList(key, fieldName, value);
        break;
      case "IS_VALUE_LIST_INDEX":
        handlerValueListIndex(key, fieldName, value, index);
        break;
      default:
        break;
    }
  };

  // const handleScrollIntoView = (i) => {
  //   !!listRef[i] &&
  //     !!listRef[i].current &&
  //     listRef[i].current.scrollIntoView({ block: "start", behavior: "smooth" });
  // };

  useEffect(() => {
    updateState("categories", "value", getParams.categories);
  }, [getParams.categories]);
  useEffect(() => {
    updateState("email", "value", getParams.email);
  }, [getParams.email]);

  useEffect(() => {
    isFinalPopupState &&
      ExtensionController.sendMessageParent({
        page: "wa-feedback",
        event: "close window",
      });
  }, [isFinalPopupState]);

  // state.submit.isScrolled && handleScrollIntoView(state.submit.firstErrorIndex);

  return (
    <Feedback
      isFinalPopupState={isFinalPopupState}
      isLoading={isLoading}
      stateUpdateHandler={stateUpdateHandler}
      listRef={listRef}
      setIsFinalPopupState={setIsFinalPopupState}
      setIsLoading={setIsLoading}
      checkIndexes={checkIndexes}
      checkErrorIndexes={checkErrorIndexes}
      updateState={updateState}
    />
  );
};
FeedbackContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default FeedbackContainer;
