import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../../helpers";
import "./index.scss";

const FeedbackEvaluationFields = (props) => {
  const { index, responseIndexes, onClickItem, checkErrorIndexes } = props;
  const activeIndex = responseIndexes[index];

  const result = [];
  for (let i = 0; i < 5; i++) {
    result.push(
      <li
        key={i}
        className={getTrueClasses(
          "PgIWF-Feedback-EvaluationFields__item",
          activeIndex !== -1 &&
            activeIndex === i &&
            "PgIWF-Feedback-EvaluationFields__item_active",
          checkErrorIndexes(responseIndexes, index) &&
            "PgIWF-Feedback-EvaluationFields__item_error",
          activeIndex !== -1 &&
            activeIndex !== i &&
            responseIndexes.includes(i) &&
            "PgIWF-Feedback-EvaluationFields__item_inactive"
        )}
        onClick={() => onClickItem(index, i)}
      >
        {i + 1}
      </li>
    );
  }

  return (
    <ul key={index} className="PgIWF-Feedback-EvaluationFields">
      {result}
    </ul>
  );
};

FeedbackEvaluationFields.propTypes = {
  index: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired,
  checkErrorIndexes: PropTypes.func.isRequired,
};

export default FeedbackEvaluationFields;
