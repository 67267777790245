import React from "react";
import PropTypes from "prop-types";

const FeedbackLocationDescription = ({ location, money }) => {
  if (!location) {
    return null;
  }
  const description = `Great! Each product idea you receive will have monthly revenue of ${money[location]}+!`;

  return <p className="PgIWF-Feedback-Location__Description">{description}</p>;
};

FeedbackLocationDescription.propTypes = {
  location: PropTypes.string,
  money: PropTypes.object.isRequired,
};

export default FeedbackLocationDescription;
