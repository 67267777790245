import axios from "axios";

const submitPopupModel = (result) => {
  return axios({
    method: "post",
    url:
      process.env.API_HOST +
      "/v1/gsheets/reports/deals/answer?sheetId=1A-mlzerX79sV6DHfiWgF02R9f-SERMYblveqqCkHjgg",
    data: result,
  });
};

export default submitPopupModel;
