import submitPopupModel from "../../models/common/submitPopupModel";

class SubmitPopupController {
  constructor(result, setIsLoading, setIsFinalPopupState) {
    this.submitPopup(result, setIsLoading, setIsFinalPopupState);
  }

  submitPopup(result, setIsLoading, setIsFinalPopupState) {
    setIsLoading(true);
    submitPopupModel(result)
      .then(() => {
        setIsFinalPopupState(true);
        localStorage.removeItem("openModal");
      })
      .catch(() => alert("Please try again later"))
      .finally(() => setIsLoading(false));
  }
}

export default SubmitPopupController;
