import React, { useEffect, useState } from "react";
import RootProvider from "../../store/providers/ReduxProvider";
import Layout from "../../layouts/LayoutIframe";
import FeedbackContainer from "../../containers/iframe/wa-feedback/FeedbackContainer";
import "../../components/iframe/wa-feedback/index.scss";

const PgIWF = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getParams, setParams] = useState({
    email: "",
    categories: "",
  });
  const updateParams = (key, value) => {
    setParams((prevState) => {
      return {
        ...prevState,
        [key]: decodeURIComponent(value),
      };
    });
  };

  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams;
    const email = urlParams.get("email");
    const ci = urlParams.get("ci");
    email && updateParams("email", email);
    ci && updateParams("categories", ci);
  }, []);

  return (
    <RootProvider>
      <Layout withTagManager={false} className="PgIWF">
        <FeedbackContainer
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          getParams={getParams}
        />
      </Layout>
    </RootProvider>
  );
};

export default PgIWF;
