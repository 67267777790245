import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import FeedbackTitle from "../FeedbackTitle";
import FeedbackEvaluationFields from "./FeedbackEvaluationFields";
import "./index.scss";
import { useSelector } from "react-redux";

const FeedbackCriteriaSort = (props) => {
  const {
    listTitles,
    stateUpdateHandler,
    checkErrorIndexes,
    componentRef,
    title,
  } = props;
  const {
    criteriaSort: { error, responseIndexes, inputValue },
  } = useSelector((state) => state.waFeedback);

  const onClickItem = (index, value) =>
    responseIndexes[index] !== value &&
    stateUpdateHandler(
      "IS_VALUE_LIST_INDEX",
      "criteriaSort",
      "responseIndexes",
      value,
      index
    );

  return (
    <div className="PgIWF-Feedback-CriteriaSort" ref={componentRef}>
      <FeedbackTitle error={error} title={title} />
      <div className="PgIWF-Feedback-CriteriaSort__wrapper">
        {listTitles.map((text, i) => (
          <div key={i} className="PgIWF-Feedback-CriteriaSort-item">
            <FeedbackEvaluationFields
              index={i}
              responseIndexes={responseIndexes}
              onClickItem={onClickItem}
              checkErrorIndexes={checkErrorIndexes}
            />
            <p className="PgIWF-Feedback-CriteriaSort-item__title">{text}</p>
          </div>
        ))}
        <div className="PgIWF-Feedback-CriteriaSort-item">
          <p className="PgIWF-Feedback-CriteriaSort-item__label">
            Specify other important criteria (optional)
          </p>
          <input
            placeholder="Input other important criteria here"
            className={getTrueClasses(
              "PgIWF-Feedback-CriteriaSort__input",
              !!inputValue && "PgIWF-Feedback-CriteriaSort__input_active"
            )}
            value={inputValue}
            name="criteriaSort"
            maxLength={100}
            onChange={(value) =>
              value !== inputValue &&
              stateUpdateHandler(
                "IS_INPUT",
                "criteriaSort",
                "inputValue",
                value
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

FeedbackCriteriaSort.propTypes = {
  listTitles: PropTypes.array.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  checkErrorIndexes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default FeedbackCriteriaSort;
