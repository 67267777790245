import React from "react";
import PropTypes from "prop-types";
import GIFLoader from "../../../../assets/images/gif/loader.gif";
import PG_IWF_FEEDBACK_CONTENT from "./index.content";
import FeedbackLocation from "./FeedbackLocation";
import FeedbackSelectionSection from "./FeedbackSelectionSection";
import FeedbackCriteriaSort from "./FeedbackCriteriaSort";
import FeedbackSubmitContainer from "../../../../containers/iframe/wa-feedback/FeedbackSubmitContainer";
import "./index.scss";

const Feedback = (props) => {
  const {
    isLoading,
    stateUpdateHandler,
    listRef,
    setIsFinalPopupState,
    setIsLoading,
    checkIndexes,
    checkErrorIndexes,
    updateState,
  } = props;
  const {
    locationOfInterest,
    preferredPrices,
    differentProductSizes,
    productSize,
    criteriaSort,
  } = PG_IWF_FEEDBACK_CONTENT;
  return (
    <div className="PgIWF-Feedback">
      <div className="PgIWF-Feedback__row">
        <FeedbackLocation
          locationOfInterest={locationOfInterest}
          stateUpdateHandler={stateUpdateHandler}
          componentRef={listRef[0]}
        />
      </div>
      <div className="PgIWF-Feedback__row">
        <FeedbackSelectionSection
          listOfAnswers={preferredPrices.priceList}
          title="2. What are your preferred prices? (U.S. dollar equivalent)"
          surveyFieldName="preferredPrices"
          stateUpdateHandler={stateUpdateHandler}
          type="IS_VALUE"
          componentRef={listRef[1]}
        />
      </div>
      <div className="PgIWF-Feedback__row">
        <FeedbackSelectionSection
          listOfAnswers={differentProductSizes.listOfAnswers}
          title="3. Would you consider selling things that come in different sizes, such as clothes (for example XL, L, M)?"
          surveyFieldName="differentProductSizes"
          stateUpdateHandler={stateUpdateHandler}
          type="IS_VALUE"
          componentRef={listRef[2]}
        />
      </div>
      <div className="PgIWF-Feedback__row">
        <FeedbackSelectionSection
          listOfAnswers={productSize.sizeList}
          title="4. What size of the product are you willing to sell?"
          surveyFieldName="productSize"
          stateUpdateHandler={stateUpdateHandler}
          type="IS_VALUE_LIST"
          componentRef={listRef[3]}
        />
      </div>
      <div className="PgIWF-Feedback__row">
        <FeedbackCriteriaSort
          title="5. Sort your criteria by importance from 1 to 5 (1 is the most important, 5 is the least important)"
          stateUpdateHandler={stateUpdateHandler}
          checkErrorIndexes={checkErrorIndexes}
          listTitles={criteriaSort.listTitles}
          componentRef={listRef[4]}
        />
      </div>
      <FeedbackSubmitContainer
        updateState={updateState}
        setIsLoading={setIsLoading}
        setIsFinalPopupState={setIsFinalPopupState}
        checkIndexes={checkIndexes}
      />
      {isLoading ? (
        <div className="PgIWF-Feedback-loader">
          <img src={GIFLoader} alt="loader" />
        </div>
      ) : null}
    </div>
  );
};

Feedback.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFinalPopupState: PropTypes.bool.isRequired,
  stateUpdateHandler: PropTypes.func.isRequired,
  listRef: PropTypes.array.isRequired,
  setIsFinalPopupState: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  checkIndexes: PropTypes.func.isRequired,
  checkErrorIndexes: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Feedback;
