import { log } from "../../helpers/index";
export class ExtensionController {
  constructor() {}
  static sendMessage(messageObject) {
    log(messageObject, "POST MESSAGE EVENT:");
    window.postMessage(messageObject, "*");
  }
  static sendMessageParent(messageObject) {
    log(messageObject, "POST MESSAGE EVENT:");
    window.parent.postMessage(messageObject, "*");
  }
}
